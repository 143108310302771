.basicShepherd {
    border-radius: 6px;
    border: 1px solid #f3f1f8;
    margin-top: 10px;

    footer {
        display: flex;
        justify-content: flex-end;
        padding: 0px 22px 16px 14px;
    }

    header {
        padding-right: 40px;
    }

    .shepherd-text {
        padding: 10px 0px 25px 0px;
        margin: 0px 20px;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.25px;
        font-family: 'Open Sans';

        div:first-child {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }

        li div:first-child {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;
        }

        li div:first-child.bold {
            font-weight: bold;
            min-width: 100px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;
        }
    }

    .shepherd-header {
        border-radius: 10px;
        background-color: white !important;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        font-weight: 500;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        padding: 10px 20px !important;

        .shepherd-title {
            display: flex;
            justify-content: flex-start;
            color: #000;
            letter-spacing: 2px;
            text-transform: uppercase;
            font-size: 12px;
            font-family: 'Ubuntu';
            color: #654ea3;
        }
    }
}

.basic-shepherd-button-secondary {
    background-color: #654ea3;
    color: #654ea3;
    border: 1px solid #654ea3;
    border: 0;
    box-shadow:
        0px 1px 4px rgba(0, 0, 0, 0.08),
        0px 0.5px 1.5px rgba(0, 0, 0, 0.08);
    height: 36px;

    &:hover {
        background-color: #f6f2f7 !important;
        color: #654ea3 !important;
    }
}

.basic-shepherd-button-primary {
    background-color: #654ea3;
    color: white;
    height: 36px;

    &:hover {
        background-color: #45356f !important;
    }
}

footer.basicShepherdFooter {
    background-color: red;
}

.marginTopBig {
    margin-top: 30px !important;
}

.basic-shepherd-button-back {
    background-color: #654ea3;
    color: #fff;

    &:hover {
        background-color: rgba(0, 0, 0, 0.36) !important;
    }
}

.shepherd-button {
    text-transform: uppercase;
    font-family: 'Ubuntu';
    letter-spacing: 1.25px;
    font-size: 14px;
    font-weight: 500;
    background: transparent !important;
    padding: 0 !important;
}

.shepherd-button:not(:disabled):hover {
    background: transparent !important;
}

.shepherd-cancel-icon {
    font-size: 24px !important;
}

.shepherd-target {
    padding: 10px;
    pointer-events: none !important;
}

li {
    margin-bottom: 10px;
}

.basicShepherd[data-popper-placement='bottom'] {
    & > .shepherd-arrow {
        height: 32px;
        width: 32px;
    }

    & > .shepherd-arrow {
        top: -16px;
    }

    & > .shepherd-arrow,
    & > .shepherd-arrow:before {
        width: 32px;
    }
}

.shepherd-element.shepherd-has-title[data-popper-placement^='bottom'] > .shepherd-arrow:before {
    height: 32px;
    background-color: #fff;
}

.basicShepherd[data-popper-placement='top'] {
    margin-top: -32px !important;

    & > .shepherd-arrow:before {
        height: 32px;
        width: 32px;
    }

    & > .shepherd-arrow {
        bottom: -16px;
        height: 32px;
        width: 32px;
    }
}

ul {
    list-style-type: none !important;
    padding-inline-start: 0 !important;
}
